import { useCallback, useEffect } from "react";
import { type AuthHubEventData } from "@aws-amplify/core/dist/esm/Hub/types";
import { getCurrentUser } from "aws-amplify/auth";
import { Hub, type HubCapsule } from "aws-amplify/utils";

import { analytics } from "../Analytics";
import { useAuthStore } from "./authStore";

export function useListenToAuthEvents() {
  const listener = useCallback(
    async (capsule: HubCapsule<"auth", AuthHubEventData>) => {
      const { payload } = capsule;
      const { event } = payload;

      const user = await getCurrentUser().catch(() => null);

      switch (event) {
        case "signInWithRedirect":
          break;
        case "signInWithRedirect_failure":
          await analytics.track("signIn_failure");
          break;
        case "tokenRefresh":
          break;
        case "tokenRefresh_failure":
          break;
        case "customOAuthState":
          break;
        case "signedIn":
          await Promise.allSettled([
            user !== null && analytics.identify(user.userId),
            analytics.track("signIn"),
            useAuthStore.getState().hydrate(),
          ]);
          break;
        case "signedOut":
          break;
      }
    },
    [],
  );

  useEffect(() => {
    const unlisten = Hub.listen(
      "auth",
      (data) => {
        listener(data).catch(console.error);
      },
      "Amplify Hub Listener",
    );

    return () => {
      unlisten();
    };
  }, [listener]);
}
