import React, { forwardRef } from "react";
import NextLink from "next/link";
import { cva, type VariantProps } from "class-variance-authority";

const link = cva(["hover:cursor-pointer"], {
  variants: {
    variant: {
      gray: "hover:text-gray-600",
      purple: "hover:text-[#3714c3]",
    },
    active: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      variant: "gray",
      active: true,
      class: "font-bold text-gray-700",
    },
    {
      variant: "gray",
      active: false,
      class: "font-bold text-gray-500",
    },
    {
      variant: "purple",
      active: false,
      class: "text-[#6852c0]",
    },
    {
      variant: "purple",
      active: true,
      class: "text-[#3714c3] font-bold",
    },
  ],
  defaultVariants: {
    variant: "gray",
  },
});

export interface LinkStyleOnlyProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof link> {}

export interface LinkProps
  extends React.ComponentProps<typeof NextLink>,
    VariantProps<typeof link> {}

export const LinkStyleOnly: React.FC<LinkStyleOnlyProps> = forwardRef<
  HTMLDivElement,
  LinkStyleOnlyProps
>(({ className, variant, active, ...props }, ref) => (
  <div className={link({ variant, active, className })} {...props} ref={ref} />
));
LinkStyleOnly.displayName = "LinkStyleOnly";

export const Link: React.FC<LinkProps> = ({
  className,
  variant,
  active,
  ...props
}) => {
  return (
    <NextLink className={link({ variant, active, className })} {...props} />
  );
};

export const NavLink: React.FC<LinkProps> = forwardRef<
  HTMLAnchorElement,
  LinkProps
>(({ className, variant, active, ...props }, ref) => {
  const mergedClass = link({ variant, active, className });
  return <NextLink className={mergedClass} {...props} ref={ref} />;
});

NavLink.displayName = "NavLink";
