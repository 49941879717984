"use client";

import { useAuthStore } from "@/services/Cognito/authStore";

import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import VerifyEmailForm from "./VerifyEmailForm";

const Authenticator = () => {
  const status = useAuthStore((ctx) => ctx.status);
  const route = useAuthStore((ctx) => ctx.route);
  const email = useAuthStore((ctx) => ctx.signUpEmail);

  if (status === "authenticated") return null;
  switch (route) {
    case "signIn":
      return <SignInForm />;
    case "signUp":
      return <SignUpForm />;
    case "resetPassword":
      if (!email) return <ForgotPasswordForm />;
      else return <ResetPasswordForm email={email} />;
    case "verifyEmail":
      if (email) return <VerifyEmailForm email={email} />;
      else useAuthStore.setState({ route: "signIn" });
  }
};

export default Authenticator;
