"use client";

import { usePathname } from "next/navigation";
import { Button } from "@/components/input/Button";
import { LinkStyleOnly, NavLink } from "@/components/ui/Link";
import { useAuthStore } from "@/services/Cognito/authStore";
import { Disclosure } from "@headlessui/react";
import BellIcon from "public/svgs/bell.svg";
import ListIcon from "public/svgs/list.svg";
import WrapGiftsLogo from "public/svgs/wrapgiftslogo.svg";

import AboutUsModal from "./AboutUsModal";
import ProfileDropdown from "./ProfileDropdown";

// using https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
const WrapGiftsNavbar = () => {
  const session = useAuthStore((ctx) => ctx.session);
  const authStatus = useAuthStore((ctx) => ctx.status);

  const showAuthenticator = useAuthStore((ctx) => ctx.showAuthenticator);
  const setRoute = useAuthStore((ctx) => ctx.setRoute);

  const toSignUp = () => {
    showAuthenticator();
    setRoute("signUp");
  };
  const toSignIn = () => {
    showAuthenticator();
    setRoute("signIn");
  };

  const groups = session?.tokens?.accessToken.payload["cognito:groups"] as
    | Array<string>
    | undefined;
  const userIsAdmin = groups?.includes("Admin");
  const userIsArtist = groups?.includes("Artist");

  const pathname = usePathname();

  const publicRoutes = [
    { name: "About Us", to: "/about" },
    { name: "My Cards", to: "/myCards" },
  ];
  const artistRoutes = [{ name: "My Templates", to: "/myTemplates" }];

  const navigation = publicRoutes.concat(
    userIsAdmin || userIsArtist ? artistRoutes : [],
  );

  return (
    <Disclosure as="nav" className="sticky top-0 z-50 bg-white">
      <div className="max-w-8xl border-gray mx-auto px-2 sm:px-6 lg:px-8 lg:py-2">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open main menu</span>

              <ListIcon className="fill-wg-purple scale-125" />
            </Disclosure.Button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <NavLink href="/" className="flex">
                <WrapGiftsLogo />
                <span className="ml-2 select-none self-center whitespace-nowrap text-2xl font-semibold">
                  Wrap.Gifts
                </span>
              </NavLink>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="hidden sm:block">
              <div className="flex space-x-6">
                {navigation.map((item) => {
                  return (
                    <div className="flex items-center" key={item.name}>
                      <NavLink href={item.to} className="text-lg font-medium">
                        {item.name}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Profile dropdown */}
            {authStatus !== "authenticated" && (
              <div className="hidden pl-6 sm:block">
                <div className="flex space-x-6">
                  <div className="flex items-center">
                    <LinkStyleOnly
                      onClick={toSignIn}
                      className="text-lg font-medium"
                    >
                      Sign In
                    </LinkStyleOnly>
                  </div>
                  <div className="flex items-center">
                    <Button onClick={toSignUp}>Sign Up</Button>
                  </div>
                </div>
              </div>
            )}
            {authStatus === "authenticated" && (
              <>
                {/* Bell Icon */}
                <button
                  type="button"
                  className="invisible rounded-full bg-white p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon
                    className="fill-wg-purple h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
                <ProfileDropdown />
              </>
            )}
          </div>
        </div>
      </div>
      <div className=" mx-3 border-b-2"></div>

      <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <Disclosure.Button
              key={item.to}
              as={NavLink}
              href={item.to}
              className={`block rounded-md px-3 py-2 text-base font-medium ${
                item.to === pathname ? "bg-gray-200" : "hover:bg-gray-100"
              }`}
            >
              {item.name}
            </Disclosure.Button>
          ))}
          {authStatus !== "authenticated" && (
            <>
              <Disclosure.Button
                as={LinkStyleOnly}
                onClick={toSignIn}
                className="block w-full rounded-md px-3 py-2 text-left 
                    text-base font-medium hover:bg-gray-100"
              >
                Sign In
              </Disclosure.Button>
              <Disclosure.Button
                onClick={toSignUp}
                className="bg-wg-purple block w-full rounded-md px-3 py-2 text-left text-base font-medium text-white 
                    hover:bg-[#5236c2]"
              >
                Sign Up
              </Disclosure.Button>
            </>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default WrapGiftsNavbar;
