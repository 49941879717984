"use client";

import React, { Suspense, useEffect, type ReactNode } from "react";
import AuthenticatorModal from "@/components/auth/AuthenticatorModal";
import { PageAnalytics } from "@/services/Analytics";
import { useListenToAuthEvents } from "@/services/Cognito/authHub";
import { Amplify } from "aws-amplify";

import amplifyconfig from "@matan/amplify-config";

import { useAuthStore } from "./Cognito/authStore";
import TrpcProvider from "./Trpc/TrpcProvider";

Amplify.configure(amplifyconfig, { ssr: true });

const AppProviders = ({ children }: { children: ReactNode }) => {
  useListenToAuthEvents();
  useEffect(() => {
    const hydrate = useAuthStore.getState().hydrate;
    hydrate().catch(console.error);
  }, []);
  return (
    <TrpcProvider>
      <Suspense>
        <PageAnalytics />
      </Suspense>
      <AuthenticatorModal />
      <div className="font-spaceGrotesk">{children}</div>
    </TrpcProvider>
  );
};

export default AppProviders;
