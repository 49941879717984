import React, { Fragment } from "react";
import { LinkStyleOnly, NavLink } from "@/components/ui/Link";
import { handleSignOut } from "@/services/Cognito/handlers";
import { Menu, Transition } from "@headlessui/react";
import PersonIcon from "public/svgs/personCircle.svg";

const ProfileDropdown = () => {
  function signOutClickHandler() {
    handleSignOut().catch(console.error);
  }
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex rounded-full bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400">
          <span className="sr-only">Open user menu</span>
          <PersonIcon className="rounded-full" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item
            as={NavLink}
            href="/myAccount"
            className={"block px-4 py-2 text-sm font-medium text-gray-700"}
            active={false}
          >
            Your Account
          </Menu.Item>
          <Menu.Item
            as={LinkStyleOnly}
            className={"block px-4 py-2 text-sm font-medium text-gray-700"}
            active={false}
            onClick={signOutClickHandler}
          >
            Sign Out
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default ProfileDropdown;
