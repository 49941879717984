import { useEffect, useRef } from "react";
import { useAuthStore } from "@/services/Cognito/authStore";
import { useOnClickOutside } from "usehooks-ts";

import Authenticator from "./Authenticator";

const AuthenticatorModal = () => {
  const status = useAuthStore((ctx) => ctx.status);
  const visible = useAuthStore((ctx) => ctx.authenticatorVisible);
  const hideAuthenticator = useAuthStore((ctx) => ctx.hideAuthenticator);

  const ref = useRef(null);
  useOnClickOutside(ref, hideAuthenticator);
  useEffect(() => {
    // prevent scrollings when modal is open
    if (visible) document.body.style.overflow = "hidden";
    return function () {
      document.body.style.overflow = "unset";
    };
  }, [visible]);

  if (!visible || status === "authenticated") return null;
  return (
    <div
      className="relative"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{
        zIndex: 60,
      }}
    >
      <div className="fixed inset-0 h-full bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
          <div ref={ref}>
            <Authenticator />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatorModal;
