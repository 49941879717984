"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

const ROUTE_PREFIXES_TO_SHOW_FOOTER = [
  "/myAccount",
  "/myCards",
  "/myTemplates",
  "/privacy",
  "/stripe",
  "/tos",
  "/about",
];

const Footer = () => {
  const pathname = usePathname();
  const showFooter =
    ROUTE_PREFIXES_TO_SHOW_FOOTER.some((item) => {
      return pathname?.startsWith(item);
    }) || pathname === "/";

  if (!showFooter) return null;

  return (
    <footer className="flex w-full flex-row justify-center space-x-2 p-2">
      <span className="text-xs text-gray-500">© Wrap.Gifts 2024</span>
      <Link
        href="/privacy"
        className="text-xs text-gray-500 hover:text-gray-700"
      >
        Privacy Policy
      </Link>
      <Link href="/tos" className="text-xs text-gray-500 hover:text-gray-700">
        Terms of Service
      </Link>
      <a
        key="termly-consent-preferences"
        href="#"
        className="text-xs text-gray-500 hover:text-gray-700"
        onClick={() => {
          window.displayPreferenceModal();
          return false;
        }}
        id="termly-consent-preferences"
      >
        Consent Preferences
      </a>
    </footer>
  );
};

export default Footer;
